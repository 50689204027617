var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TablePlaceholder',{attrs:{"data":_vm.history},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var count = ref.count;
return [_c('LTable',{attrs:{"data":items,"loading":_vm.isLoading && !_vm.isLoadingPagination,"count":count,"virtual-scroll":"","backend-pagination":"","hide-pagination":"","hide-mobile":"","hide-sticky":""},scopedSlots:_vm._u([(count > items.length)?{key:"footer",fn:function(){return [_c('b-button',{staticClass:"button-more is-justify-content-center py-4",attrs:{"loading":_vm.isLoadingPagination,"type":"borderless is-fullwidth has-text-info"},on:{"click":function($event){return _vm.store.updateHistoryRequests()}}},[_c('span',{staticClass:"is-absolute mb-0 has-text-black",staticStyle:{"left":"0"}},[_c('span',{staticClass:"is-hidden-mobile pr-1"},[_vm._v(" "+_vm._s(_vm.$t("common.buttons.countRows"))+" ")]),_vm._v(" "+_vm._s(items.length)+"/"+_vm._s(count)+" ")]),_c('span',{staticClass:"has-text-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$t("common.buttons.loadMore"))+" ")])])]},proxy:true}:null,{key:"empty",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("admin.traffic.modal.table.labels.empty"))+" ")]},proxy:true}],null,true)},[_c('b-table-column',{attrs:{"label":_vm.$t('admin.traffic.modal.table.labels.id')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var intId = ref_row.intId;
var createdAt = ref_row.createdAt;
var type = ref_row.type;
return [_c('router-link',{staticClass:"has-text-info",attrs:{"to":{
                        name: 'admin:traffic',
                        query: _vm.setQuery(intId, createdAt, type)
                    },"target":"_blank"}},[_vm._v(" "+_vm._s(intId)+" ")])]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t('admin.traffic.modal.table.labels.type')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var type = ref.row.type;
return [_vm._v(" "+_vm._s(_vm.$t(("admin.traffic.filters.values.type." + type)))+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t('admin.traffic.modal.table.labels.createdAt')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var createdAt = ref.row.createdAt;
return [_vm._v(" "+_vm._s(_vm.moment(createdAt, "DD.MM.YY HH:mm"))+" ")]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }