



































import SectionWrapper from "@/components/Common/SectionWrapper.vue";
import TrafficRequestFilters from "@/components/Admin/Traffic/TrafficRequestFilters.vue";
import TrafficRequestTable from "@/components/Admin/Traffic/TrafficRequestTable.vue";
import { useTrafficRequestCreateModal } from "@/stores/admin/traffic/modal/trafficRequestCreateModalStore";
import TrafficRequestCreateModal from "@/components/Admin/Traffic/Traffic/modal/Create/TrafficRequestCreateModal.vue";
import Permissions from "@/components/Common/Permissions.vue";
import TrafficRequestChartModal from "@/components/Admin/Traffic/Traffic/modal/Chart/TrafficRequestChartModal.vue";
import { useTrafficRequestAnalyseModal } from "@/stores/admin/traffic/modal/trafficRequestAnalyseModalStore";
import { useTrafficRequestChatModal } from "@/stores/admin/traffic/modal/trafficRequestChatModalStore";
import TrafficRequestChatModal from "@/components/Admin/Traffic/Traffic/Table/TrafficRequestChatModal.vue";
import VerticalsFilter from "@/components/Common/Fields/VerticalsFilter.vue";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const store = useTrafficRequestCreateModal();
  const storeAnalyse = useTrafficRequestAnalyseModal();
  const storeChat = useTrafficRequestChatModal();
  return {
    store,
    storeAnalyse,
    storeChat
  };
};
__sfc_main.components = Object.assign({
  Permissions,
  SectionWrapper,
  VerticalsFilter,
  TrafficRequestFilters,
  TrafficRequestTable,
  TrafficRequestCreateModal,
  TrafficRequestChartModal,
  TrafficRequestChatModal
}, __sfc_main.components);
export default __sfc_main;
