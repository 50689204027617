


























































import { useTrafficRequestCreateModal } from "@/stores/admin/traffic/modal/trafficRequestCreateModalStore";
import { storeToRefs } from "pinia";
import CustomDatepicker from "@/components/Common/Date/CustomDatepicker.vue";
import { computed } from "@vue/composition-api";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const store = useTrafficRequestCreateModal();
  const {
    recommendation
  } = storeToRefs(store);
  const shouldAddRecommendation = computed(() => recommendation.value.audience || recommendation.value.trafficSource || recommendation.value.landingLink || recommendation.value.comment);
  return {
    store,
    recommendation,
    shouldAddRecommendation
  };
};
__sfc_main.components = Object.assign({
  CustomDatepicker
}, __sfc_main.components);
export default __sfc_main;
