<template>
    <Component
        :is="componentInstance"
        v-bind="$attrs"
        :value="value"
        :get-data-vuex="getUsers"
        :placeholder="placeholder"
        :multiple="multiple"
        class="white"
        field="label"
        prop="value"
        @[event]="$emit(event, $event)">
    </Component>
</template>

<script>
  import { UsersSelect } from "@core/mixins/select/usersSelect";

  export default {
    name: "CapacityExternalWebmastersFilter",
    mixins: [UsersSelect],

    props: {
      routeParam: {
        type: String,
        default: "externalWebmasterId"
      },

      namespaceModule: {
        type: String,
        default: "admin/capacityAndBumps/capacityAnalyseExternalWebmastersList"
      }
    },

    methods: {
      async getUsers (label = "", isLoading = false) {
        await this.getExternalWebmasterFilters(this.namespaceModule, this.list, this.$route.query[this.routeParam] ?? this.value,
                                               this.routeParam, label, isLoading, this.filterOptions, this.excludedFilterOptions);
        return this.list;
      }
    }
  };
</script>

<style scoped></style>