







































































































































































































































import TablePlaceholder from "@/components/Common/Table/TablePlaceholder.vue";
import { useTrafficRequest } from "@/stores/admin/traffic/trafficRequestStore";
import { storeToRefs } from "pinia";
import LTable from "@/components/Common/LTable.vue";
import { useWait } from "@/stores/common/waitStore";
import { ref } from "@vue/composition-api";
import { formatEmptyString, moment } from "@core/filters";
import WebmasterOutput from "@/components/Common/Output/WebmasterOutput.vue";
import CustomOfferOutput from "@/components/Common/Output/CustomOfferOutput.vue";
import { TrafficRequestTypeEnum } from "@core/store/types/admin/traffic/enums/trafficRequestTypeEnum";
import { modificationValues } from "@/helpers/modificationValues";
import AdminOutput from "@/components/Common/Output/AdminOutput.vue";
import ShowMore from "@/components/Common/ShowMore.vue";
import ColoredStatus from "@/components/Common/ColoredStatus.vue";
import { TrafficRequestStatusEnum } from "@core/store/types/admin/traffic/enums/trafficRequestStatusEnum";
import TrafficRequestActions from "@/components/Admin/Traffic/TrafficRequestActions.vue";
import InlineSvg from "vue-inline-svg";
import chatIcon from "@/assets/Table/chat.svg";
import { TrafficRequestSort, TrafficRequestUnificationItem } from "@core/store/types/admin/traffic/trafficRequest";
import { TrafficRequestStateEnum } from "@core/store/types/admin/traffic/enums/trafficRequestStateEnum";
import { usePermissions } from "@/stores/common/auth/permissionsStore";
import { actionWithToast } from "@/helpers/actionWithToast";
import { DialogProgrammatic } from "buefy";
import { i18n } from "@core/plugins";
import { OrderEnum } from "@core/store/types/common/enums/OrderEnum";
import LabelWithAnnotation from "@/components/Common/Tooltip/LabelWithAnnotation.vue";
import CompactList from "@/components/Common/Lists/CompactList.vue";
import UserOutput from "@/components/Common/Output/UserOutput.vue";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const colorStatus: Record<string, string> = {
    [TrafficRequestStatusEnum.APPLIED]: "is-success",
    [TrafficRequestStatusEnum.ACCEPTED]: "is-success",
    [TrafficRequestStatusEnum.AWAITING]: "is-warning",
    [TrafficRequestStatusEnum.EXPIRED]: "is-dark",
    [TrafficRequestStatusEnum.REJECTED]: "is-danger",
    [TrafficRequestStatusEnum.DELETED]: "is-light"
  };
  const store = useTrafficRequest();
  const {
    data,
    type
  } = storeToRefs(store);
  const permissionsStore = usePermissions();
  const isLoading = useWait(store, store.GET_TRAFFIC_REQUEST);
  const loadingRowId = ref<string | null>(null);
  const defaultSort = ref<[TrafficRequestSort, OrderEnum] | never[]>([]);
  function deleteTrafficRequest(id: string) {
    loadingRowId.value = id;
    DialogProgrammatic.confirm({
      title: i18n.t("admin.traffic.modal.table.labels.deleteRequest.title") as string,
      message: i18n.t("admin.traffic.modal.table.labels.deleteRequest.message") as string,
      confirmText: i18n.t("admin.traffic.modal.table.labels.deleteRequest.confirm") as string,
      cancelText: i18n.t("common.buttons.cancel") as string,
      type: "is-danger is-light",
      onConfirm: async () => {
        await actionWithToast(store.deleteTrafficRequest(id), "admin.traffic.modal.table.labels.deleteRequest.errors");
      }
    });
    loadingRowId.value = null;
  }
  function showAnalyseRequest(webmaster: string, offer: string) {
    store.showAnalyseRequest(webmaster, offer);
  }
  function showTrafficChat(row: TrafficRequestUnificationItem) {
    store.showTrafficChat(row);
  }
  function showEditTraffic(row: TrafficRequestUnificationItem) {
    store.showEditTraffic(row);
  }
  async function acceptRecommendation(id: string) {
    loadingRowId.value = id;
    await actionWithToast(store.acceptRecommendationTrafficRequest(id), "admin.traffic.modal.body.errors");
    loadingRowId.value = null;
  }
  async function rejectRecommendation(id: string) {
    loadingRowId.value = id;
    await actionWithToast(store.rejectRecommendationTrafficRequest(id), "admin.traffic.modal.body.errors");
    loadingRowId.value = null;
  }
  async function applyNewTerms(id: string) {
    loadingRowId.value = id;
    await actionWithToast(store.applyNewTermsTrafficRequest(id), "admin.traffic.modal.body.errors");
    loadingRowId.value = null;
  }
  function getShowNotification({
    notificationState,
    webmaster
  }: TrafficRequestUnificationItem) {
    switch (notificationState) {
      case TrafficRequestStateEnum.QUALITY:
        return permissionsStore.hasPermissions(["TRAFFIC_REQUESTS.MANAGE"]);
      case TrafficRequestStateEnum.AFFILIATE:
        return webmaster.isBindedToCurrentAdmin;
      default:
        return false;
    }
  }
  async function updateSortings(sort: TrafficRequestSort, order: OrderEnum) {
    defaultSort.value = [sort, order];
    store.$patch({
      sort,
      order
    });
    await store.getTrafficRequest();
  }
  return {
    formatEmptyString,
    moment,
    TrafficRequestTypeEnum,
    modificationValues,
    chatIcon,
    colorStatus,
    store,
    data,
    type,
    isLoading,
    loadingRowId,
    defaultSort,
    deleteTrafficRequest,
    showAnalyseRequest,
    showTrafficChat,
    showEditTraffic,
    acceptRecommendation,
    rejectRecommendation,
    applyNewTerms,
    getShowNotification,
    updateSortings
  };
};
__sfc_main.components = Object.assign({
  TablePlaceholder,
  LTable,
  LabelWithAnnotation,
  AdminOutput,
  CompactList,
  UserOutput,
  WebmasterOutput,
  CustomOfferOutput,
  ShowMore,
  ColoredStatus,
  InlineSvg,
  TrafficRequestActions
}, __sfc_main.components);
export default __sfc_main;
