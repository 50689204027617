var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TablePlaceholder',{attrs:{"data":_vm.data},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var count = ref.count;
return [_c('LTable',{ref:"table",attrs:{"data":items,"count":count,"loading":_vm.isLoading,"default-sort":_vm.defaultSort,"backend-pagination":"","query-pagination":"","backend-sorting":"","virtual-scroll":"","with-options":""},on:{"update":function($event){return _vm.store.getTrafficRequest()},"sort":_vm.updateSortings}},[_c('b-table-column',{attrs:{"label":_vm.$t('admin.traffic.table.labels.id')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.intId)+" ")]}}],null,true)}),_c('TableColumn',{attrs:{"sortable":"","field":"createdAt","header-class":"header-date pr-2","label":_vm.$t('admin.traffic.table.labels.createdAt')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.moment(row.createdAt, "DD.MM.YY HH:mm"))+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"header-class":"header-date"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('LabelWithAnnotation',{attrs:{"tooltip":_vm.$t("admin.traffic.table.labels.expireDate.tooltip"),"hide":_vm.type === _vm.TrafficRequestTypeEnum.RECOMMENDATIONS,"tooltip-position":"is-top","type":"is-info"}},[_c('span',{staticStyle:{"white-space":"wrap"}},[_vm._v(" "+_vm._s(_vm.$t('admin.traffic.table.labels.expireDate.label'))+" ")])])]},proxy:true},{key:"default",fn:function(ref){
var expireDate = ref.row.expireDate;
return [_vm._v(" "+_vm._s(_vm.moment(expireDate, "DD.MM.YY HH:mm"))+" ")]}}],null,true)}),_c('TableColumn',{attrs:{"sortable":"","field":"executedAt","header-class":"header-date pr-2","label":_vm.$t('admin.traffic.table.labels.executedAt')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var executedAt = ref.row.executedAt;
return [_vm._v(" "+_vm._s(executedAt ? _vm.moment(executedAt, "DD.MM.YY HH:mm") : _vm.formatEmptyString())+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t('admin.traffic.table.labels.creator')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var creator = ref.row.creator;
return [_c('AdminOutput',{attrs:{"admin":{ intId: creator.intId, login: creator.login, id: creator.id }}})]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t('admin.traffic.table.labels.executor'),"header-class":"header-date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var executor = ref.row.executor;
return [(executor)?_c('AdminOutput',{attrs:{"admin":{ intId: executor.intId, login: executor.login, id: executor.id }}}):[_vm._v(" "+_vm._s(_vm.formatEmptyString())+" ")]]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t('admin.traffic.table.labels.manager')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var admins = ref.row.webmaster.admins;
return [_c('CompactList',{attrs:{"items":admins},scopedSlots:_vm._u([{key:"listItem",fn:function(ref){
var item = ref.item;
return [_c('AdminOutput',{attrs:{"admin":item}})]}},{key:"tooltipItem",fn:function(ref){
var item = ref.item;
return [_c('UserOutput',{attrs:{"user":item}})]}},{key:"singleItem",fn:function(ref){
var item = ref.item;
return [_c('AdminOutput',{attrs:{"admin":item}})]}}],null,true)})]}}],null,true)}),_c('TableColumn',{attrs:{"sortable":"","field":"webmasterIntId","header-class":"nowrap","cell-class":"nowrap","label":_vm.$t('admin.traffic.table.labels.webmaster')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('WebmasterOutput',{attrs:{"webmaster":row.webmaster}})]}}],null,true)}),_c('TableColumn',{attrs:{"sortable":"","field":"offerIntId","cell-class":"nowrap","label":_vm.$t('admin.traffic.table.labels.offer')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('CustomOfferOutput',{attrs:{"offer":row.offer}})]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t('admin.traffic.table.labels.comment'),"visible":_vm.type === _vm.TrafficRequestTypeEnum.RECOMMENDATIONS},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var comment = ref.row.comment;
return [_c('ShowMore',{attrs:{"as-modal":"","data":comment,"max-string":40}})]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t('admin.traffic.table.labels.audience'),"visible":_vm.type === _vm.TrafficRequestTypeEnum.RECOMMENDATIONS},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var audience = ref.row.audience;
return [_c('ShowMore',{attrs:{"as-modal":"","data":audience,"max-string":30}})]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t('admin.traffic.table.labels.trafficSource'),"visible":_vm.type === _vm.TrafficRequestTypeEnum.RECOMMENDATIONS},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var trafficSource = ref.row.trafficSource;
return [_c('ShowMore',{attrs:{"as-modal":"","data":trafficSource,"max-string":30}})]}}],null,true)}),_c('b-table-column',{attrs:{"cell-class":"nowrap","label":_vm.$t('admin.traffic.table.labels.capacity'),"visible":_vm.type !== _vm.TrafficRequestTypeEnum.RECOMMENDATIONS},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.modificationValues(row.oldCapacity, row.newCapacity))+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"cell-class":"nowrap","label":_vm.$t('admin.traffic.table.labels.landing'),"visible":_vm.type === _vm.TrafficRequestTypeEnum.RECOMMENDATIONS},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var landing = ref.row.landing;
return [_c('ShowMore',{attrs:{"as-modal":"","data":landing,"max-string":30}})]}}],null,true)}),_c('b-table-column',{attrs:{"cell-class":"nowrap","label":_vm.$t('admin.traffic.table.labels.payout'),"visible":_vm.type !== _vm.TrafficRequestTypeEnum.RECOMMENDATIONS},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.modificationValues(row.oldPayout, row.newPayout))+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t('admin.traffic.table.labels.comment'),"visible":_vm.type !== _vm.TrafficRequestTypeEnum.RECOMMENDATIONS},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var comment = ref.row.comment;
return [_c('ShowMore',{attrs:{"as-modal":"","data":comment,"max-string":40}})]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t('admin.traffic.table.labels.status')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var status = ref.row.status;
return [_c('ColoredStatus',{attrs:{"type":_vm.colorStatus[status]}},[_vm._v(" "+_vm._s(_vm.$t(("admin.traffic.table.values.status." + status)))+" ")])]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t('admin.traffic.table.labels.chat')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-button',{staticClass:"chat-button",on:{"click":function($event){return _vm.showTrafficChat(row)}}},[_c('InlineSvg',{attrs:{"src":_vm.chatIcon}}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.getShowNotification(row)),expression:"getShowNotification(row)"}],staticClass:"alert"})],1)]}}],null,true)}),_c('b-table-column',{attrs:{"header-class":"has-action pr-4","cell-class":"has-action pr-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('TrafficRequestActions',{attrs:{"row-data":row,"type":_vm.type,"loading":_vm.loadingRowId === row.id},on:{"deleteRequest":_vm.deleteTrafficRequest,"showAnalyse":_vm.showAnalyseRequest,"acceptRequest":_vm.acceptRecommendation,"rejectRequest":_vm.rejectRecommendation,"applyRequest":_vm.applyNewTerms,"showEdit":_vm.showEditTraffic}})]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }