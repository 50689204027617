






































































































































import LFilters from "@/components/Common/LFilters.vue";
import { useTrafficRequest } from "@/stores/admin/traffic/trafficRequestStore";
import { storeToRefs } from "pinia";
import { useWait } from "@/stores/common/waitStore";
import { TrafficRequestFilters } from "@core/store/types/admin/traffic/trafficRequest";
import { useFiltersConfirm } from "@/stores/common/filtersConfirmStore";
import { computed, onBeforeUnmount } from "@vue/composition-api";
import { TrafficRequestTypeEnum } from "@core/store/types/admin/traffic/enums/trafficRequestTypeEnum";
import { i18n } from "@core/plugins";
import CustomDatepicker from "@/components/Common/Date/CustomDatepicker.vue";
import AccessControlFilter from "@/components/Admin/Select/AccessControlFilter.vue";
import OffersSelect from "@/components/Common/Select/OffersSelect.vue";
import Select from "@/components/Common/Select/Select.vue";
import { RecommendationRequestStatusEnum } from "@core/store/types/admin/traffic/enums/recommendationRequestStatusEnum";
import CustomCheckbox from "@/components/Common/Controls/CustomCheckbox.vue";
import { usePermissions } from "@/stores/common/auth/permissionsStore";
import { TrafficRequestStateEnum } from "@core/store/types/admin/traffic/enums/trafficRequestStateEnum";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import type { TranslateResult } from "vue-i18n";
type statusType = {
  value: RecommendationRequestStatusEnum | {
    [key: string]: boolean;
  };
  label: TranslateResult;
};
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const store = useTrafficRequest();
  const {
    filters,
    type
  } = storeToRefs(store);
  const confirmStore = useFiltersConfirm();
  const {
    confirmFilters
  } = storeToRefs(confirmStore);
  const isLoading = useWait(store, store.GET_TRAFFIC_REQUEST);
  const permissionsStore = usePermissions();
  const filtersType = computed(() => confirmFilters.value.type?.[0] || TrafficRequestTypeEnum.RECOMMENDATIONS);
  const types = computed(() => Object.values(TrafficRequestTypeEnum).map((value: TrafficRequestTypeEnum) => ({
    value,
    label: i18n.t(`admin.traffic.filters.values.type.${value}`)
  })));
  const trueValueState = computed(() => permissionsStore.hasPermissions(["TRAFFIC_REQUESTS.MANAGE"]) ? TrafficRequestStateEnum.QUALITY : TrafficRequestStateEnum.AFFILIATE);
  function onConfirmFilters({
    filters
  }: {
    filters: TrafficRequestFilters;
  }) {
    store.$patch(state => {
      state.filters = {
        ...state.filters,
        ...filters
      };
      if (filters.type?.[0] === TrafficRequestTypeEnum.RECOMMENDATIONS) {
        state.filters.termsStatus = null;
        confirmFilters.value.termsStatus = null;
      } else {
        state.filters.recommendationStatus = null;
        confirmFilters.value.recommendationStatus = null;
      }
    });
    store.getTrafficRequest();
  }
  function updateType(value: TrafficRequestTypeEnum) {
    confirmFilters.value.type = [value];
  }
  function getStatuses() {
    const items = Object.values(RecommendationRequestStatusEnum).map(value => ({
      label: i18n.t(`admin.traffic.table.values.status.${value}`),
      value
    })) as statusType[];
    items.push({
      value: {
        isDeleted: true
      },
      label: i18n.t("admin.traffic.table.values.status.DELETED")
    });
    return {
      items,
      count: items.length
    };
  }
  function getTermsStatuses() {
    const items = [{
      value: {
        isApplied: true,
        isDeleted: false
      },
      label: i18n.t("admin.traffic.table.values.status.APPLIED")
    }, {
      value: {
        isApplied: false,
        isDeleted: false
      },
      label: i18n.t("admin.traffic.table.values.status.AWAITING")
    }, {
      value: {
        isApplied: false,
        isDeleted: true
      },
      label: i18n.t("admin.traffic.table.values.status.DELETED")
    }];
    return {
      items,
      count: items.length
    };
  }
  onBeforeUnmount(() => {
    store.$reset();
  });
  return {
    TrafficRequestTypeEnum,
    VerticalsEnum,
    store,
    filters,
    type,
    confirmFilters,
    isLoading,
    filtersType,
    types,
    trueValueState,
    onConfirmFilters,
    updateType,
    getStatuses,
    getTermsStatuses
  };
};
__sfc_main.components = Object.assign({
  LFilters,
  CustomDatepicker,
  AccessControlFilter,
  OffersSelect,
  Select,
  CustomCheckbox
}, __sfc_main.components);
export default __sfc_main;
