







































































































import { defineProps, defineEmits } from "@vue/runtime-dom";
import { TrafficRequestUnificationItem } from "@core/store/types/admin/traffic/trafficRequest";
import ShowMore from "@/components/Common/ShowMore.vue";
import { computed, ref } from "@vue/composition-api";
import WebmasterOutput from "@/components/Common/Output/WebmasterOutput.vue";
import CustomOfferOutput from "@/components/Common/Output/CustomOfferOutput.vue";
interface Props {
  rowData: TrafficRequestUnificationItem;
  loading: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  rowData: {
    key: "rowData",
    required: true,
    type: null
  },
  loading: {
    key: "loading",
    required: true,
    type: Boolean
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const isActive = ref(false);
  const intId = computed(() => props.rowData.intId);
  const webmaster = computed(() => props.rowData.webmaster);
  const offer = computed(() => props.rowData.offer);
  const audience = computed(() => props.rowData.audience);
  const trafficSource = computed(() => props.rowData.trafficSource);
  const landing = computed(() => props.rowData.landing);
  const comment = computed(() => props.rowData.comment);
  function onDropdownToggle(active: boolean) {
    isActive.value = active;
  }
  return {
    emit,
    isActive,
    intId,
    webmaster,
    offer,
    audience,
    trafficSource,
    landing,
    comment,
    onDropdownToggle
  };
};
__sfc_main.components = Object.assign({
  WebmasterOutput,
  CustomOfferOutput,
  ShowMore
}, __sfc_main.components);
export default __sfc_main;
