































































import { useTrafficRequestCreateModal } from "@/stores/admin/traffic/modal/trafficRequestCreateModalStore";
import { storeToRefs } from "pinia";
import { watchEffect } from "@vue/composition-api";
import { useWait } from "@/stores/common/waitStore";
import { defaultFormatter, formatEmptyString, moment } from "@core/filters";
import { fixedCurrency } from "@core/flowMethods";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const store = useTrafficRequestCreateModal();
  const {
    context,
    hasShowHistory
  } = storeToRefs(store);
  const isLoading = useWait(store, store.GET_CONTEXT_REQUEST);
  watchEffect(() => {
    if (hasShowHistory.value) {
      store.getContextRequests();
    } else {
      context.value = {};
    }
  });
  return {
    defaultFormatter,
    formatEmptyString,
    moment,
    fixedCurrency,
    context,
    hasShowHistory,
    isLoading
  };
};
export default __sfc_main;
