





















































































































import { useTrafficRequestCreateModal } from "@/stores/admin/traffic/modal/trafficRequestCreateModalStore";
import { storeToRefs } from "pinia";
import CustomDatepicker from "@/components/Common/Date/CustomDatepicker.vue";
import CustomCheckbox from "@/components/Common/Controls/CustomCheckbox.vue";
import LabelWithAnnotation from "@/components/Common/Tooltip/LabelWithAnnotation.vue";
import CapacityExternalWebmastersFilter from "@/components/Admin/Select/CapacityExternalWebmastersFilter.vue";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const store = useTrafficRequestCreateModal();
  const {
    newTerms,
    hasShowHistory,
    confirmNow,
    externalWebmaster,
    errorPayout,
    hasPayoutLow,
    offerId,
    webmasterId
  } = storeToRefs(store);
  return {
    store,
    newTerms,
    hasShowHistory,
    confirmNow,
    externalWebmaster,
    errorPayout,
    hasPayoutLow,
    offerId,
    webmasterId
  };
};
__sfc_main.components = Object.assign({
  CustomDatepicker,
  CapacityExternalWebmastersFilter,
  LabelWithAnnotation,
  CustomCheckbox
}, __sfc_main.components);
export default __sfc_main;
