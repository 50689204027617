











import CommonChartTemplate from "@/components/Common/Chart/CommonChartTemplate.vue";
import { chartColors } from "@/components/Charts/chart.config";
import { tooltipBuilder } from "@/components/Charts/tooltipBuilder";
import { defineProps, withDefaults } from "@vue/runtime-dom";
import { TrafficRequestAnalyseData } from "@core/store/types/admin/traffic/trafficRequest";
import { computed, ref } from "@vue/composition-api";
import { defaultFormatter, formatCurrency, moment } from "@core/filters";
import { usePermissions } from "@/stores/common/auth/permissionsStore";
import { storeToRefs } from "pinia";
import { i18n } from "@core/plugins";
interface Props {
  data: TrafficRequestAnalyseData | null;
  loading?: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  data: {
    key: "data",
    required: true,
    type: null
  },
  loading: {
    key: "loading",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const path = "admin.traffic.modal.chart";
  const props = __props;
  const storePermissions = usePermissions();
  const {
    isShowRedemption
  } = storeToRefs(storePermissions);
  const selected = ref({});
  const fields = computed(() => [{
    name: "countLeadsIntegrated",
    color: chartColors.default.success
  }, {
    name: "conversionApprove",
    color: chartColors.default.info
  }, isShowRedemption.value && {
    name: "conversionPayout",
    color: chartColors.default.light
  }, {
    name: "webmasterPayout",
    color: chartColors.default.warning
  }, {
    name: "softCapacity",
    color: chartColors.default.violet
  }]);
  const xLabels = computed(() => props?.data?.map(({
    date
  }) => moment(date, "DD.MM.YYYY")) || []);
  const datasets = computed(() => props?.data ? fields.value.map(item => item && {
    name: item.name,
    label: i18n.t(`${path}.${item.name}`),
    color: item.color,
    backgroundColor: item.color,
    data: dataMapper(item.name),
    isPercent: item.name.includes("conversion")
  }) : []);
  const labels = computed(() => datasets.value.reduce((acc, {
    name,
    label
  }) => {
    acc[name] = label;
    return acc;
  }, {}));
  const formatters = computed(() => ({
    conversionApprove: (value: number, index: number) => `${value === 0 ? 0 : defaultFormatter(value)} % (${defaultFormatter(props.data![index].countLeadsAccepted)})`,
    conversionPayout: (value: number) => isNaN(value) || value === 0 ? 0 : `${defaultFormatter(value)} %`,
    webmasterPayout: (value: number, index: number) => formatCurrency(value, props.data![index].offer.finance.currency)
  }));
  function dataMapper(name: string) {
    return name.includes("conversion") ? props.data!.map(item => item[name] * 100) : props.data!.map(item => item[name]);
  }
  function tooltipFormatter(seriesName: string, pinSeriesName: string) {
    return (params: TrafficRequestAnalyseData) => tooltipBuilder(params, labels.value, formatters.value, seriesName, pinSeriesName);
  }
  function controlSelected(item: {
    selected: {
      [key: string]: unknown;
    };
  }) {
    selected.value = item.selected;
  }
  return {
    selected,
    xLabels,
    datasets,
    tooltipFormatter,
    controlSelected
  };
};
__sfc_main.components = Object.assign({
  CommonChartTemplate
}, __sfc_main.components);
export default __sfc_main;
