





















































import ModalBox from "@/components/Common/Modals/ModalBox.vue";
import { useTrafficRequestAnalyseModal } from "@/stores/admin/traffic/modal/trafficRequestAnalyseModalStore";
import { storeToRefs } from "pinia";
import { useWait } from "@/stores/common/waitStore";
import ChartDetailFilters from "./ChartDetailFilters.vue";
import TrafficRequestAnalyseChart from "./TrafficRequestAnalyseChart.vue";
import { watchEffect } from "@vue/composition-api";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const store = useTrafficRequestAnalyseModal();
  const {
    isActive,
    webmaster,
    offer,
    data
  } = storeToRefs(store);
  const isLoading = useWait(store, store.GET_TRAFFIC_REQUESTS_ANALYSE);
  watchEffect(() => {
    store.getTrafficRequestsAnalyse();
  });
  return {
    isActive,
    webmaster,
    offer,
    data,
    isLoading
  };
};
__sfc_main.components = Object.assign({
  ModalBox,
  ChartDetailFilters,
  TrafficRequestAnalyseChart
}, __sfc_main.components);
export default __sfc_main;
