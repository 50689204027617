





































































import { moment } from "@core/filters";
import { defineLinksInString } from "@core/helpers/defineLinksInString";
import { defineProps, withDefaults, defineEmits } from "@vue/runtime-dom";
import { ref, computed, watch, ComponentPublicInstance } from "@vue/composition-api";
import storeInstance from "@/store";
import { Admin } from "@core/store/types/common/User";
import { TrafficRequestChatActionEnum } from "@core/store/types/admin/traffic/enums/trafficRequestChatActionEnum";
interface Props {
  loading?: boolean;
  viewOnly?: boolean;
  sending?: boolean;
  maxLength?: number;
  data?: {
    [key: string]: any;
  }[];
}
const __sfc_main = {};
__sfc_main.props = {
  loading: {
    key: "loading",
    required: false,
    type: Boolean
  },
  viewOnly: {
    key: "viewOnly",
    required: false,
    type: Boolean,
    default: false
  },
  sending: {
    key: "sending",
    required: false,
    type: Boolean,
    default: false
  },
  maxLength: {
    key: "maxLength",
    required: false,
    type: Number,
    default: 2000
  },
  data: {
    key: "data",
    required: false,
    type: Array,
    default: () => []
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const comment = ref("");
  const chat = ref<ComponentPublicInstance<any> | null>(null);
  const textarea = ref<ComponentPublicInstance<any> | null>(null);
  const selfId = computed(() => storeInstance.state.admin.profile.user.id);
  const isDisabled = computed(() => !comment.value?.length && !props.sending);
  function sendMessage() {
    emit("send", comment.value);
    comment.value = "";
  }
  watch(() => props.data, () => {
    setTimeout(() => {
      chat.value.scrollTop = chat.value.scrollHeight;
    }, 0);
  });
  watch(() => comment.value, value => {
    if (!value) {
      const el = textarea.value.$refs.input.$refs.textarea;
      el.style.height = "56px";
    }
  });
  function changeMinHeight() {
    const el = textarea.value.$refs.input.$refs.textarea;
    el.style.height = "56px";
    el.style.height = `${el.scrollHeight}px`;
  }
  function hasAmI(creator?: Admin) {
    return creator?.id === selfId.value;
  }
  return {
    moment,
    defineLinksInString,
    TrafficRequestChatActionEnum,
    comment,
    chat,
    textarea,
    isDisabled,
    sendMessage,
    changeMinHeight,
    hasAmI
  };
};
export default __sfc_main;
