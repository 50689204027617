









import CustomDatepicker from "@/components/Common/Date/CustomDatepicker.vue";
import { useTrafficRequestAnalyseModal } from "@/stores/admin/traffic/modal/trafficRequestAnalyseModalStore";
import { storeToRefs } from "pinia";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const store = useTrafficRequestAnalyseModal();
  const {
    datepicker
  } = storeToRefs(store);
  return {
    datepicker
  };
};
__sfc_main.components = Object.assign({
  CustomDatepicker
}, __sfc_main.components);
export default __sfc_main;
