











































































































import { defineProps, defineEmits } from "@vue/runtime-dom";
import { TrafficRequestUnificationItem } from "@core/store/types/admin/traffic/trafficRequest";
import InlineSvg from "vue-inline-svg";
import canceled from "@/assets/common/canceled.svg";
import iconChart from "@/assets/Offer/gridicons_stats-down.svg";
import iconRequest from "@/assets/Offer/request.svg";
import iconPen from "@/assets/Table/pen.svg";
import { computed } from "@vue/composition-api";
import { TrafficRequestStatusEnum } from "@core/store/types/admin/traffic/enums/trafficRequestStatusEnum";
import { usePermissions } from "@/stores/common/auth/permissionsStore";
import { TrafficRequestTypeEnum } from "@core/store/types/admin/traffic/enums/trafficRequestTypeEnum";
import Permissions from "@/components/Common/Permissions.vue";
import storeInstance from "@/store";
import TrafficRequestDropdownRecommendations from "@/components/Admin/Traffic/Traffic/Table/TrafficRequestDropdownRecommendations.vue";
import TrafficRequestDropdownNewTerms from "@/components/Admin/Traffic/Traffic/Table/TrafficRequestDropdownNewTerms.vue";
interface Props {
  rowData: TrafficRequestUnificationItem;
  type: TrafficRequestTypeEnum;
  loading: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  rowData: {
    key: "rowData",
    required: true,
    type: null
  },
  type: {
    key: "type",
    required: true,
    type: null
  },
  loading: {
    key: "loading",
    required: true,
    type: Boolean
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const permissionsStore = usePermissions();
  const isAwaiting = computed(() => props.rowData.status === TrafficRequestStatusEnum.AWAITING);
  const canDelete = computed(() => permissionsStore.hasPermissions(["TRAFFIC_REQUESTS.MANAGE"]));
  const selfId = computed(() => storeInstance.state.admin.profile.user.id);
  const hasCreator = computed(() => selfId.value === props.rowData.creator.id);
  const isBinded = computed(() => permissionsStore.hasPermissions(["TRAFFIC_REQUESTS.HANDLE.ALL"]) || props.rowData.webmaster.isBindedToCurrentAdmin);
  return {
    canceled,
    iconChart,
    iconRequest,
    iconPen,
    TrafficRequestTypeEnum,
    emit,
    isAwaiting,
    canDelete,
    hasCreator,
    isBinded
  };
};
__sfc_main.components = Object.assign({
  InlineSvg,
  Permissions,
  TrafficRequestDropdownRecommendations,
  TrafficRequestDropdownNewTerms
}, __sfc_main.components);
export default __sfc_main;
