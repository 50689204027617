




































































import LTable from "@/components/Common/LTable.vue";
import { useTrafficRequestCreateModal } from "@/stores/admin/traffic/modal/trafficRequestCreateModalStore";
import { storeToRefs } from "pinia";
import { watch } from "@vue/composition-api";
import { useWait } from "@/stores/common/waitStore";
import { moment } from "@core/filters";
import { setQuery } from "@/components/Common/NavigationBar/Notifications/setQuery";
import TablePlaceholder from "@/components/Common/Table/TablePlaceholder.vue";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const store = useTrafficRequestCreateModal();
  const {
    history,
    hasShowHistory,
    offerId,
    webmasterId
  } = storeToRefs(store);
  const isLoading = useWait(store, store.GET_HISTORY_REQUEST);
  const isLoadingPagination = useWait(store, store.UPDATE_HISTORY_REQUEST);
  watch(() => `${offerId.value}-${webmasterId.value}`, async () => {
    if (hasShowHistory.value) {
      await store.getHistoryRequests();
    } else {
      history.value = {};
    }
  }, {
    immediate: true
  });
  return {
    moment,
    setQuery,
    store,
    history,
    isLoading,
    isLoadingPagination
  };
};
__sfc_main.components = Object.assign({
  TablePlaceholder,
  LTable
}, __sfc_main.components);
export default __sfc_main;
