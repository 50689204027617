
























































































import { defineProps, defineEmits } from "@vue/runtime-dom";
import { TrafficRequestUnificationItem } from "@core/store/types/admin/traffic/trafficRequest";
import ShowMore from "@/components/Common/ShowMore.vue";
import { computed, ref } from "@vue/composition-api";
import WebmasterOutput from "@/components/Common/Output/WebmasterOutput.vue";
import OfferOutput from "@/components/Common/Output/OfferOutput.vue";
import { modificationValues } from "@/helpers/modificationValues";
interface Props {
  rowData: TrafficRequestUnificationItem;
  loading: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  rowData: {
    key: "rowData",
    required: true,
    type: null
  },
  loading: {
    key: "loading",
    required: true,
    type: Boolean
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const isActive = ref(false);
  const intId = computed(() => props.rowData.intId);
  const webmaster = computed(() => props.rowData.webmaster);
  const externalWebmaster = computed(() => props.rowData.externalWebmaster);
  const offer = computed(() => props.rowData.offer);
  const oldCapacity = computed(() => props.rowData.oldCapacity);
  const newCapacity = computed(() => props.rowData.newCapacity);
  const oldPayout = computed(() => props.rowData.oldPayout);
  const newPayout = computed(() => props.rowData.newPayout);
  const comment = computed(() => props.rowData.comment);
  function onDropdownToggle(active: boolean) {
    isActive.value = active;
  }
  return {
    modificationValues,
    emit,
    isActive,
    intId,
    webmaster,
    externalWebmaster,
    offer,
    oldCapacity,
    newCapacity,
    oldPayout,
    newPayout,
    comment,
    onDropdownToggle
  };
};
__sfc_main.components = Object.assign({
  WebmasterOutput,
  OfferOutput,
  ShowMore
}, __sfc_main.components);
export default __sfc_main;
